import React, { Component }  from 'react';

import { withTranslation, Trans } from "react-i18next";
import Navigation from '../Navigation';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';


import i18n from "../../i18n";

// console.log(i18n.languages[0]);

const INITIAL_STATE = {
  checkboxEN: false,
  EN: 'en'
};

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClick = e => {
     e.preventDefault();
     this.props.history.goBack();
   };

render() {
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    this.props.history.goBack();
  };
      return (
        <div className="pageContainer">
          <Col xs={12} className="pt-3" >
            <h2><Trans i18nKey="common:lng.title" /></h2>

              <Button block className="languageBtn" onClick={() => changeLanguage('en')}>English</Button>
              <Button block className="languageBtn" onClick={() => changeLanguage('ru')}>русский</Button>
              <Button block className="languageBtn" onClick={() => changeLanguage('cn')}>简体中文</Button>
              <Button block className="languageBtn" onClick={() => changeLanguage('fr')}>French</Button>
              <Button block className="languageBtn" onClick={() => changeLanguage('dk')}>Danish</Button>
              <Button block className="languageBtn" onClick={() => changeLanguage('de')}>German</Button>

            <Button className="carlsberg mt-4" variant="secondary" block onClick={this.onClick}><Trans i18nKey="common:cta.cancel" /></Button>
          </Col>
        </div>
  );
  }
}

export default Language;
