import React from 'react';

import { withTranslation, Trans } from "react-i18next";
import Navigation from '../Navigation';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const FAQS = () => (
  <div className="pageContainer">
    <Row className="mx-0 px-3">
        <Col className="pt-3 mx-0 px-0" xs={12}>
          <h2><Trans i18nKey="common:faq.title"/></h2>

          <h3><Trans i18nKey="common:faq.paraOneTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraOneBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraThreeTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraThreeBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraFourTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraFourBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraFiveTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraFiveBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraSixTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraSixBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraSevenTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraSevenBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraEightTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraEightBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraNineTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraNineBody"/>
          </p>

          <h3><Trans i18nKey="common:faq.paraTenTitle"/></h3>
          <p className="mb-3">
            <Trans i18nKey="common:faq.paraTenBody">
            Visit the WHO WE ARE section on the intranet to find out more or send us an email to: <a href="mailto:whoweare@carlsberg.com">whoweare@carlsberg.com</a>
            </Trans>
          </p>
      </Col>
    </Row>
</div>
);

export default FAQS;
