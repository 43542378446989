import React from 'react';

import { withTranslation, Trans } from "react-i18next";
import Navigation from '../Navigation';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Terms = () => (
  <div className="pageContainer">
  <Row className="mx-0 px-3">
    <Col className="pt-3 mx-0 px-0" xs={12}>
        <h2><Trans i18nKey="common:consent.title"/></h2>
        <h3><Trans i18nKey="common:consent.paraOneTitle"/></h3>
        <p className="mb-2">
          <Trans i18nKey="common:consent.paraOneBody"/>
        </p>
    </Col>
    <Col className="pt-2 mx-0 px-0" xs={12}>
        <h3><Trans i18nKey="common:consent.paraTwoTitle"/></h3>
        <p className="mb-2">
          <Trans i18nKey="common:consent.paraTwoBody"/>
        </p>
        <ul className="paragraphList">
          <li>
            <Trans i18nKey="common:consent.paraTwoBulletOne"/>
          </li>
          <li>
            <Trans i18nKey="common:consent.paraTwoBulletTwo"/>
          </li>
          <li>
            <Trans i18nKey="common:consent.paraTwoBulletThree">
              External channels Carlsberg Group’s website (<a href="https://www.carlsberggroup.com" target="_blank">www.carlsberggroup.com</a>), LinkedIn, Twitter and Instagram.
            </Trans>
          </li>
        </ul>
    </Col>
    <Col className="pt-2 mx-0 px-0" xs={12}>
      <h3><Trans i18nKey="common:consent.paraThreeTitle"/></h3>
      <p className="mb-2">
        <Trans i18nKey="common:consent.paraThreeBody">
        You can withdraw your consent at any time by sending an email to <a href="mailto:whoweare@carlsberg.com">whoweare@carlsberg.com</a>.
        </Trans>
        &nbsp;
        <Trans i18nKey="common:consent.paraThreeBodyTwo">
         If you choose to withdraw your consent, Carlsberg Group may no longer use your personal data based on the consent you gave.
        For further information on how we process your personal data and your rights, please see our Privacy Policy at <a href="https://www.carlsberg.com/en/privacy-policy" target="_blank">https://www.carlsberg.com/en/privacy-policy</a>.
        Your rights are outlined in section 6) Your rights and contacting us.
        </Trans>
      </p>
    </Col>
</Row>
</div>
);

export default Terms;
