import React, { Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AnimatedSwitch } from 'react-router-transition';
import Loadable from 'react-loadable';

import '../bootstrap.min.css';
import TopBarProgress from "react-topbar-progress-indicator";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ScrollToTop from '../ScrollToTop';

import Navigation from '../Navigation';
import NavTakedown from '../Navigation/takedown.js';

import Brand from '../Navigation/brand';
import Terms from '../Vanilla';
import FAQS from '../Vanilla/faqs';
import Language from '../Language';

import * as ROUTES from '../../constants/routes';

TopBarProgress.config({
  barColors: {
    "0": "#ffb400",
    "1.0": "#ffb400"
  },
  shadowBlur: 5
});

const Loading = () => (
    <TopBarProgress />
);

const Landing = Loadable({
  loader: () => import("../Landing"),
  loading: Loading
});
const Takedown = Loadable({
  loader: () => import("../Landing/takedown.js"),
  loading: Loading
});
const Teaser = Loadable({
  loader: () => import("../Landing/teaser.js"),
  loading: Loading
});
const VideoPage = Loadable({
  loader: () => import("../VideoUploader"),
  loading: Loading
});
const HomePage = Loadable({
  loader: () => import("../Home"),
  loading: Loading
});
const ThankYou = Loadable({
  loader: () => import("../ThankYou"),
  loading: Loading
});

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
});

const App = () => (
      <Router history={browserHistory}>

        <Brand />
        <Container fluid className="px-0 mb-2">
        <NavTakedown/>
          <Row className="mx-0 px-0">
          <Route exact path={ROUTES.HOME} component={HomePage}/>
            <Suspense fallback={(<TopBarProgress />)}>
            <AnimatedSwitch
            atEnter={{ opacity: 0.2 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            mapStyles={(styles) => ({
              opacity: styles.opacity
            })}
            className="route-wrapper"
            wrapperComponent='span'
            >
            <Route exact path={ROUTES.LANDING} component={Takedown} />
            {/* <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route exact path={ROUTES.TEASER} component={Teaser} />
            <Route exact path={ROUTES.THANKYOU} component={ThankYou} />
            <Route exact path={ROUTES.UPLOAD} component={VideoPage} /> */}

            </AnimatedSwitch>
            <ScrollToTop exact path={ROUTES.TERMS} component={Terms}/>
            <ScrollToTop exact path={ROUTES.FAQS} component={FAQS}/>
            <AnimatedSwitch
            atEnter={{ offset: 3, opacity: 0.8 }}
            atLeave={{ opacity: 0 }}
            atActive={{ offset: 0, opacity: 1 }}
            mapStyles={(styles) => ({
              opacity: styles.opacity,
              transform: `translateY(${styles.offset}%)`,
            })}
            className="route-wrapper"
            wrapperComponent='span'
            >
            <Route exact path={ROUTES.LANGUAGE} component={Language} />
            </AnimatedSwitch>
            </Suspense>
          </Row>
        </Container>

      </Router>
);


export default App;
