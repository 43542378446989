import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import './index.scss';

require('dotenv').config();

ReactDOM.render(
      <App />,
  document.getElementById('root'),
);
