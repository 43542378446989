import React from 'react';
import { LinkContainer } from "react-router-bootstrap";
import { Link } from 'react-router-dom';

import { withTranslation, Trans } from "react-i18next";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Navigation = () => (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
);

const NavigationAuth = () => (
  <Navbar expand="lg" className="px-2" sticky="top">
    <LinkContainer to={ROUTES.HOME}>
      <Navbar.Brand >Carlsberg</Navbar.Brand>
    </LinkContainer>
    <SignOutButton />
  </Navbar>
);

const NavigationNonAuth = () => (
  <Nav className="justify-content-center" activeKey="/">
    <Nav.Item>
      <Link to={ROUTES.UPLOAD}><Trans i18nKey="common:nav.upload" /></Link>
    </Nav.Item>
    <Nav.Item>
      <Link to={ROUTES.FAQS}><Trans i18nKey="common:nav.faq" /></Link>
    </Nav.Item>
    <Nav.Item>
      <Link to={ROUTES.TERMS}><Trans i18nKey="common:nav.consent" /></Link>
    </Nav.Item>
    <Nav.Item>
      <Link to={ROUTES.LANGUAGE}><Trans i18nKey="common:nav.lng" /></Link>
    </Nav.Item>
  </Nav>
);

export default withTranslation('common')(Navigation);
