import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import common_fr from './translations/fr/common.json';
import common_en from './translations/en/common.json';
import common_de from './translations/de/common.json';
import common_cn from './translations/cn/common.json';
import common_it from './translations/it/common.json';
import common_ru from './translations/ru/common.json';
import common_dk from './translations/dk/common.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'fr', 'de', 'cn', 'it', 'ru', 'dk'];

const resources = {
    en: {
        common: common_en               // 'common' is our custom namespace
    },
    fr: {
        common: common_fr
    },
    de: {
        common: common_de              // 'common' is our custom namespace
    },
    it: {
        common: common_it
    },
    ru: {
        common: common_ru               // 'common' is our custom namespace
    },
    cn: {
        common: common_cn
    },
    dk: {
        common: common_dk
    },
};

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources,
    whitelist: availableLanguages,
    nonExplicitWhitelist: true,
    lng: 'cn',
    fallbackLng: 'en',
    detector: {
        // order and from where user language should be detected
        order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng'
      },
});

export default i18n;