import React from 'react';
import { LinkContainer } from "react-router-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import logo from './../../images/logo.svg';
import wordmark from './../../images/mark.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faSearch, faUserCircle, faReceipt, faPlusSquare, faHome } from '@fortawesome/free-solid-svg-icons';

const Brand = () => (
    <Row style={{background:`#b49132`}} className="mx-0 py-3 px-3">
      <Col xs={9}>
        <Link to={ROUTES.LANDING} className="logo-text">
            <span className="logo-text-bold">Together,</span> we <br />
            created the story <br />
            of <span className="logo-text-bold">who we are</span>
        </Link>
      </Col>
      <Col xs={3} className="px-0">
        <Image
          src={logo}
          className="logo-image"
        />
      </Col>
    </Row>
);


export default withTranslation('common')(Brand);
